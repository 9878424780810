<template>
   <!-- Standard modal content -->
   <div
      :id="modalId"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
   >
      <div class="modal-dialog">
         <div class="modal-content">
            <div class="modal-header">
               <h4 class="modal-title" id="modalLabel">
                  <slot name="header"> Modal Heading </slot>
               </h4>
               <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
               ></button>
            </div>
            <div class="modal-body">
               <slot name="body">
                  <h6>Text in a modal</h6>
                  <p>
                     Duis mollis, est non commodo luctus, nisi erat porttitor
                     ligula.
                  </p>
                  <hr />
                  <h6>Overflowing text to show scroll behavior</h6>
                  <p>
                     Cras mattis consectetur purus sit amet fermentum. Cras
                     justo odio, dapibus ac facilisis in, egestas eget quam.
                     Morbi leo risus, porta ac consectetur ac, vestibulum at
                     eros.
                  </p>
                  <p>
                     Praesent commodo cursus magna, vel scelerisque nisl
                     consectetur et. Vivamus sagittis lacus vel augue laoreet
                     rutrum faucibus dolor auctor.
                  </p>
                  <p>
                     Aenean lacinia bibendum nulla sed consectetur. Praesent
                     commodo cursus magna, vel scelerisque nisl consectetur et.
                     Donec sed odio dui. Donec ullamcorper nulla non metus
                     auctor fringilla.
                  </p>
               </slot>
            </div>
            <div class="modal-footer">
               <slot name="footer">
                  <button
                     type="button"
                     class="btn btn-light"
                     data-bs-dismiss="modal"
                  >
                     Close
                  </button>
                  <button type="button" class="btn btn-primary">
                     Save changes
                  </button>
               </slot>
            </div>
         </div>
         <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
   </div>
   <!-- /.modal -->
</template>
<script>
export default {
   props: ["modalId"],
};
</script>