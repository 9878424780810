<template>
   <div class="container-fluid">
      <div class="row">
         <div class="col-12">
            <div class="page-title-box">
               <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                     <!-- <li class="breadcrumb-item active">Locations</li> -->
                  </ol>
               </div>
               <h4 class="page-title">All Meetings</h4>
            </div>
         </div>
      </div>
      <!-- end page title -->
      <div class="mb-1">
         <a
            href="#"
            class="badge me-2"
            :class="[
               invitee == '' ? 'badge-outline-primary' : 'badge-soft-primary',
            ]"
            @click="filterBy('')"
         >
            All
         </a>
         <a
            href="#"
            class="badge me-2"
            :class="[
               invitee != '' ? 'badge-outline-primary' : 'badge-soft-primary',
            ]"
            @click="filterBy(getAuthId)"
         >
            Invited by me
         </a>
         <!-- <button class="badge badge-soft-primary me-2">Mine</button> -->
      </div>
      <div class="row">
         <div class="col-lg-12">
            <div class="card">
               <div class="card-body">
                  <div class="row mb-2">
                     <div class="col-lg-8">
                        <form
                           class="d-flex flex-wrap align-items-center"
                           @submit.prevent="fetchAllMeetings"
                        >
                           <!-- Search -->
                           <div class="me-3">
                              <div class="input-group">
                                 <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search meeting..."
                                    v-model="search"
                                 />
                                 <button
                                    class="
                                       btn
                                       input-group-text
                                       btn-dark
                                       waves-effect waves-light
                                    "
                                    type="button"
                                    @click="fetchAllMeetings"
                                 >
                                    <i class="fe-search"></i>
                                 </button>
                              </div>
                           </div>
                           <!-- Clear search -->
                           <button
                              type="button"
                              class="
                                 btn btn-danger
                                 waves-effect waves-light
                                 me-2
                              "
                              v-if="search != ''"
                              @click="clearSearch"
                           >
                              <i class="mdi mdi-close"></i>
                              Clear
                           </button>

                           <!-- <div class="me-sm-3">
                              <select
                                 class="form-select form-select my-1 my-lg-0"
                                 id="status-select"
                              >
                                 <option selected="">Choose...</option>
                                 <option value="1">Paid</option>
                                 <option value="2">
                                    Awaiting Authorization
                                 </option>
                                 <option value="3">Payment failed</option>
                                 <option value="4">Cash On Delivery</option>
                                 <option value="5">Fulfilled</option>
                                 <option value="6">Unfulfilled</option>
                              </select>
                           </div> -->
                        </form>
                     </div>
                     <div class="col-lg-4">
                        <div class="text-lg-end">
                           <router-link
                              class="nav-link"
                              :to="{ name: 'meeting-create' }"
                           >
                              <button
                                 type="button"
                                 class="
                                    btn btn-primary
                                    waves-effect waves-light
                                    mb-2
                                    me-2
                                 "
                              >
                                 <i class="mdi mdi-calendar-plus me-1"></i> Book
                                 meeting
                              </button>
                           </router-link>
                        </div>
                     </div>
                     <!-- end col-->
                  </div>

                  <place-holder v-if="!meetings"></place-holder>

                  <template v-if="meetings">
                     <div class="table-responsive">
                        <table class="table table-striped mb-3">
                           <thead>
                              <tr>
                                 <th>No.</th>
                                 <th>Title</th>
                                 <th>Room</th>
                                 <th>Date</th>
                                 <th>Internal</th>
                                 <th>External</th>
                                 <th>Invitee</th>
                                 <th>Status</th>
                                 <th>Action</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr
                                 v-for="(meeting, index) in meetings.data"
                                 :key="meeting.id"
                              >
                                 <td>{{ indexNo + index }}</td>
                                 <td class="text-black">{{ meeting.title }}</td>
                                 <td>{{ meeting.room.name }}</td>
                                 <td>{{ meeting.meetingDate }}</td>
                                 <td>
                                    <template
                                       v-for="(user, index) in meeting.users"
                                       :key="index"
                                    >
                                       <span>{{ user.name }}, </span>
                                    </template>
                                 </td>
                                 <td>{{ meeting.external_participants }}</td>
                                 <td>{{ meeting.invitee.name }}</td>
                                 <td>
                                    <a
                                       href="javascript:void(0);"
                                       @click="handleMeetingStatus(meeting)"
                                    >
                                       <meeting-status
                                          :status="meeting.status"
                                       ></meeting-status>
                                    </a>
                                 </td>
                                 <td>
                                    <span
                                       v-if="
                                          meeting.user_id ==
                                             this.$store.getters[
                                                'auth/getAuthId'
                                             ]
                                       "
                                    >
                                       <router-link
                                          class="action-icon"
                                          :to="{
                                             name: 'meeting-update',
                                             params: { id: meeting.id },
                                          }"
                                       >
                                          <i
                                             class="mdi mdi-square-edit-outline"
                                          ></i>
                                       </router-link>

                                       <i
                                          class="
                                             mdi mdi-file-document-edit-outline
                                             action-icon
                                          "
                                          @click="attachFileModal(meeting)"
                                       ></i>
                                    </span>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>

                     <pagination
                        v-model="page"
                        :records="meetings.total"
                        :per-page="per_page"
                        @paginate="getResults($event)"
                     />
                  </template>
               </div>
            </div>
            <!-- end card -->
         </div>
      </div>
   </div>

   <MeetingModal :modalId="'meeting-minute'">
      <template v-slot:header>
         {{ meeting.title }}
      </template>

      <template v-slot:body>
         <div class="table-responsive mb-3">
            <table class="table table-centered mb-0">
               <thead class="table-light">
                  <tr>
                     <th class="border-0">Name</th>
                     <th class="border-0">Last Uploaded</th>
                     <th class="border-0" style="width: 80px">Action</th>
                  </tr>
               </thead>
               <tbody>
                  <tr
                     v-for="meetingMinute in meeting.meetingMinutes"
                     :key="meetingMinute.id"
                  >
                     <td>
                        <span class="fw-semibold"
                           ><a
                              :href="
                                 'http://docs.google.com/viewerng/viewer?url=' +
                                 meetingMinute.media.url
                              "
                              target="blank"
                              class="text-reset"
                              >{{ meetingMinute.media.name }}</a
                           ></span
                        >
                     </td>
                     <td>
                        <p class="mb-0">{{ meetingMinute.created_at }}</p>
                     </td>
                     <td>
                        <a :href="meetingMinute.media.url">
                           <i class="mdi mdi-download action-icon"></i>
                        </a>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>

         <form
            id="upload-meetingMinute"
            @submit.prevent="uploadFile"
            enctype="multipart/form-data"
            ref="uploadForm"
         >
            <div class="mb-3">
               <label for="example-fileinput" class="form-label"
                  >Attach meeting minute</label
               >
               <input
                  type="file"
                  @change="selectFile"
                  class="form-control"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  required
               />
            </div>
         </form>
         <a :href="getMomTemplateFileUrl">
            Click here to download MOM template
            <i class="mdi mdi-download"></i>
         </a>
      </template>

      <template v-slot:footer>
         <button
            type="submit"
            form="upload-meetingMinute"
            class="btn btn-light"
            v-if="!loading"
         >
            Upload
         </button>

         <button
            class="btn btn-primary"
            type="button"
            disabled=""
            v-if="loading"
         >
            <span
               class="spinner-border spinner-border-sm me-1"
               role="status"
               aria-hidden="true"
            ></span>
            Uploading...
         </button>
      </template>
   </MeetingModal>

   <MeetingModal :modalId="'admin-meeting-status'">
      <template v-slot:header>
         {{ meeting.title }}
      </template>

      <template v-slot:body>
         <div class="row mb-2">
            <label for="meeting-status" class="form-label"
               >Status <span class="text-danger">*</span></label
            >
            <div class="col-md-2">
               <div class="form-check form-check-success">
                  <input
                     class="form-check-input"
                     type="radio"
                     name="meetingStatus"
                     id="meetingFinished"
                     value="Finished"
                     v-model="meeting.status"
                  /><label class="form-check-label" for="meetingFinished"
                     >Finished</label
                  >
               </div>
            </div>
            <div class="col-md-2">
               <div class="form-check form-check-warning">
                  <input
                     class="form-check-input"
                     type="radio"
                     name="meetingStatus"
                     id="meetingPending"
                     value="Pending"
                     v-model="meeting.status"
                  /><label class="form-check-label" for="meetingPending"
                     >Pending</label
                  >
               </div>
            </div>
            <div class="col-md-2">
               <div class="form-check form-check-danger">
                  <input
                     class="form-check-input"
                     type="radio"
                     name="meetingStatus"
                     id="meetingCanceled"
                     value="Canceled"
                     v-model="meeting.status"
                  /><label class="form-check-label" for="meetingCanceled"
                     >Canceled</label
                  >
               </div>
            </div>
         </div>
      </template>

      <template v-slot:footer>
         <button
            type="button"
            @click="updateMeetingStatus()"
            class="btn btn-light"
            data-bs-dismiss="modal"
         >
            Update
         </button>
      </template>
   </MeetingModal>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import $ from "jquery";
//Components
import meetingStatus from "../../components/shared/meetingStatus.vue";
import MeetingModal from "../../components/shared/Modal.vue";
import { useToast } from "vue-toastification";

export default {
   setup() {
      const toast = useToast();
      return { toast };
   },
   name: "Index",
   components: {
      meetingStatus,
      MeetingModal,
   },

   data() {
      return {
         page: 1,
         per_page: 10,
         meetings: null,
         meeting: {
            id: null,
            title: null,
            meetingMinutes: null,
            status: "",
         },
         search: "",
         invitee: "",
         loading: false,
         baseUrl: process.env.VUE_APP_BASE_URL,
      };
   },
   methods: {
      async fetchAllMeetings() {
         this.$Progress.start();
         await axios
            .get(
               `${this.baseUrl}admin/v1/meetings?paginate=1&per_page=${this.per_page}&page=${this.page}&search=${this.search}&invitee=${this.invitee}`
            )
            .then((response) => {
               this.meetings = response.data.data;
               this.$Progress.finish();
            })
            .catch(() => this.$Progress.fail());
      },
      getResults(event) {
         this.page = event;
         this.fetchAllMeetings();
      },
      filterBy(invitee) {
         this.invitee = invitee;
         this.page = 1;
         this.fetchAllMeetings();
      },
      clearSearch() {
         this.search = "";
         this.fetchAllMeetings();
      },
      attachFileModal(meeting) {
         this.meeting.id = meeting.id;
         this.meeting.title = meeting.title;
         this.meeting.meetingMinutes = meeting.meeting_minutes;
         $("#meeting-minute").modal("show");
      },
      selectFile(e) {
         this.meeting.meetingMinute = e.target.files[0];
      },
      async uploadFile() {
         this.loading = true;

         const config = {
            headers: {
               "content-type": "multipart/form-data",
            },
         };
         let formData = new FormData();
         formData.append("meeting_minute_media[0]", this.meeting.meetingMinute);

         await axios
            .post(
               `${this.baseUrl}admin/v1/meetings/${this.meeting.id}/upload`,
               formData,
               config
            )
            .then((response) => {
               this.meeting.meetingMinutes = response.data.data.meeting_minutes;
               this.meeting.meetingMinute = null;
               this.$refs.uploadForm.reset();

               this.fetchAllMeetings();
               this.toast.success("Successfully uploaded!");
            })
            .catch(() => {
               this.toast.error("Something went wrong!");
            });

         this.loading = false;
      },
      handleMeetingStatus(meeting) {
         if (meeting.user_id != this.$store.getters["auth/getAuthId"]) return;

         this.meeting.id = meeting.id;
         this.meeting.title = meeting.title;
         this.meeting.status = meeting.status;
         $("#admin-meeting-status").modal("show");
      },
      async updateMeetingStatus() {
         await axios
            .post(
               `${this.baseUrl}admin/v1/meetings/${this.meeting.id}/update-status`,
               this.meeting
            )
            .then(() => {
               this.meetings.data.map((meeting) => {
                  if (meeting.id === this.meeting.id) {
                     meeting.status = this.meeting.status;
                  }
               });
               this.toast.success("Successfully updated!");
            })
            .catch(() => {
               this.toast.error("Something went wrong!");
            });
      },
   },
   created() {
      this.fetchAllMeetings();
   },
   computed: {
      ...mapGetters("auth", ["getAuthName", "getAuthId"]),
      ...mapGetters(["getMomTemplateFileUrl"]),
      indexNo() {
         return (this.page - 1) * this.per_page + 1;
      },
   },
};
</script>

<style>
</style>