<template>
   <span class="badge rounded-pill" :class="meetingStatusClass">
      {{ status }}
   </span>
</template>
<script>
export default {
   props: ["status"],
   computed: {
      meetingStatusClass() {
         var statusClass = "";
         switch (this.status) {
            case "Finished":
               statusClass = "bg-success";
               break;
            case "Pending":
               statusClass = "bg-warning";
               break;
            case "Cancel":
               statusClass = "bg-danger";
               break;
            default:
               statusClass = "bg-danger";
               break;
         }

         return statusClass;
      },
   },
};
</script>